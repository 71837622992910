import {applyMiddleware, compose, createStore} from "redux"
import {createLogger} from "redux-logger"
import thunk from "redux-thunk"
import promise from "redux-promise-middleware"
import reducer from "../reducers"
import {createMemoryHistory} from 'history'
import DevTools from '../containers/Debug';
// import packageJson from '../../package.json';

/**
 * logger który umozliwia logowanie globalnie bledow
 * zapisuje je do props.error.message
 * @param store
 */
// const logger = store => next => action => {
//
//
//     try {
//         console.log(action);
//         let result = next(action);
//         console.log({
//             action: action,
//             prev: store.getState(),
//             next: result
//         });
//         if (action.type.endsWith("_REJECTED")) {
//             console.warn(store.getState());
//             console.warn(next.getState());
//         }
//         return result;
//     } catch (err) {
//         console.warn(store.getState());
//         console.error(err)
//     }
// };

export const history = createMemoryHistory();
const enhancer = compose(
    // Middleware you want to use in development:
    ((process.env.REACT_APP_STAGE !== 'production') && (process.env.NODE_ENV !== 'test') ?
        // (!!packageJson.debug ?
        applyMiddleware(promise, thunk, createLogger()) :
        applyMiddleware(promise, thunk)),


    // applyMiddleware(promise, thunk),
    // Required! Enable Redux DevTools with the monitors you chose
    DevTools.instrument()
);
//const middleware = applyMiddleware(promise, thunk, createLogger(), logger);
const store = createStore(reducer, enhancer);
export default store
